@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.layoutWrapperHeader {
  width: 100%;
  min-height: 200px;
  background: url('./hero.png') #2B2F68;
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  @media(--viewportMedium) {
    min-height: 350px;
    padding: 0;
  }
}
.applyBtn {
  background: #78264F;
  border-radius: 100px;
  border: none;
  padding: 10px 37px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--white);
  cursor: pointer;

  margin-top: 20px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(19, 19, 19, 0.5);
  background-blend-mode: multiply;
}

.headerContent {
  z-index: 1;
  max-width: 788px;
}

.headerContent h1 {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  color: #F2F1EF;

  /* margin: 0; */
  @media(--viewportMedium) {
    font-size: 48px;
    line-height: 60px;
  }
}

.headerContent h3 {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeight);
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: #F2F1EF;

  @media(--viewportMedium) {
    font-size: 31px;
    line-height: 39px;
    font-weight: var(--fontWeightSemiBold);
  }
}

/* Second Section */
.secondSectionWrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  padding: 70px 20px;
}

/* Third Section */
.thirdSectionWrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  padding: 70px 20px;
}

.pattern {
  position: absolute;
  top: 10px;
  right: 10px;

  @media(--viewportMedium) {
    position: absolute;
    top: 50px;
    right: 30px;
  }
}

.pattern img {
  width: 50px;
  height: 70px;

  @media(--viewportMedium) {
    width: 100%;
    height: 100%;
  }
}

.secondSectionContainer {
  max-width: 1068px;
  margin: 0 auto;
}

.secondSectionContainer>h4 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2B2F68;

  @media(--viewportMedium) {
    font-size: 25px;
    line-height: 30px;
  }
}

.thirdSectionContainer>h1 {
  
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2B2F68;
  align-items: center;
  @media(--viewportMedium) {
    font-size: 40px;
    line-height: 30px;
  }
}

/* Plans */
.sommlyPlansWrapper {
  width: 100%;
  padding: 70px 20px;
  position: relative;
  background-image: url('Pattern3.png');
  background-repeat: repeat;
  background-position: center;
  /* @media (--viewportMedium) {
    padding: 70px 0;
  } */
}

/* .sommlyPlansWrapper::before {
  content: '';
  display: block;
  background: url('../../assets/sommly-pattern-footer.png');
  background-repeat: repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.05;
  z-index: 10;
} */

.plansWrapper {
  max-width: 1068px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  z-index: 90;

  @media(--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    z-index: 90;
  }
}


.plan {
  background: var(--burgundy);
  color: var(--white);
  padding: 30px 30px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.plan:nth-child(2) {
  background: var(--burgundy);
}

.plan:nth-child(2) h1 {
  color: var(--white)
}

.contentHeader {
  width: 100%;
  height: 100px;
  align-items: center;
}

.plan h2 {
  font-family: var(--fontFamily);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  color: var(--white);
  text-transform: capitalize;
  align-items: center;
  margin: 0;
}

.plan h1 {
  font-family: var(--fontFamily);
  font-size: 26px;
  font-weight: var(--fontWeightBold);
  color: var(--white);
  align-items: center;
  margin: 0;
}

.plan p {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: var(--fontWeight);
  padding: 20px 0;
  line-height: 18px;
  align-items: center;
  border-top: 1px solid var(--rose);
  border-bottom: 1px solid var(--rose);
}

.plan span {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  align-items: center;
}

.offer {
  margin: 0;
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: var(--fontWeight);
  padding: 10px 0 20px;
  line-height: 18px;
}

.plan ul {
  padding: 0 16px;
}

.plan ul li {
  list-style-type: disc;

  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeight);
  line-height: 20px;
}

.planBtn {
  border: none;
  border-radius: 25px;
  padding: 8px 45px;
  background: var(--white);
  color: var(--blue);

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: var(--fontWeightBold);
  cursor: pointer;
  line-height: 18px;
  cursor: pointer;
  z-index: 100;
}
